import "./App.css";
import { RouterProvider } from "react-router-dom";
import { appRouter } from "./config/router";
import { ConfigProvider } from "antd";
import { AuthProvider } from "./contexts/authContext";
import "./styles/antd.css";
import "./styles/customStyle.css";
import { FormProvider } from "./contexts/formContext";
import {BrandBaseDto, BrandService, CategoryBaseDto, CategoryService, OpenAPI} from "./api";
import {LoadingStateProvider} from "./contexts/loadingStateContext";
import {ColorsService} from './api'
export const DEFAULT_FALLBACK =  process.env.REACT_APP_BASE_URL + '/assets/image-fallback.png';
export const COLORS: Record<string, string> = {}
export const COLORS_HEX: Record<string, string> = {}
export const BRANDS: BrandBaseDto[] = [];
export const CATEGORIES: CategoryBaseDto[] = []


console.log(process.env.NODE_ENV);
console.log(process.env.REACT_APP_NODE_ENV);

function App() {

  OpenAPI.BASE =  process.env.REACT_APP_BASE_URL || ''
  
  const localToken = localStorage.getItem("token");
  if(localToken) OpenAPI.HEADERS = {
    Authorization: `Bearer ${localToken}`
  }

  ColorsService.getAllColorsController().then(s => {
    Object.keys(s).forEach(k => {
      COLORS[k] = s[k];
      COLORS_HEX[s[k]] = k;
    })
  })

  BrandService
    .findAllBrandController(undefined, 500)
    .then(f => {
      BRANDS.push(...f.data)
    })

  CategoryService
    .findAllCategoryController(undefined, 500)
    .then(f => {
      CATEGORIES.push(...f.data)
    })

  return (
    <div>
      <AuthProvider>
        <FormProvider>
          <LoadingStateProvider>
            <ConfigProvider
                theme={{
                  token: {
                    colorPrimary: "#02296C",
                  },
                  components: {
                    Form: {
                      labelColor: "#02296C",
                      labelFontSize: 12,
                      verticalLabelPadding: 2,
                      labelHeight: 18,
                      labelColonMarginInlineStart: 2,
                      labelColonMarginInlineEnd: 8,
                      itemMarginBottom: 22,
                    },
                    Input: {
                      colorPrimary: "#1C4FA7",
                      fontWeightStrong: 600,
                    },
                    Collapse: {
                      fontSize: 12,
                      fontSizeIcon: 10,
                      colorTextHeading: "#1C4FA7",
                      colorBgContainer: "white",
                    },
                    Checkbox: {
                      colorText: "#888888",
                      fontSize: 10,
                    },
                    InputNumber: {
                      colorTextPlaceholder: "#888888",
                    },
                    Tabs: {
                      itemSelectedColor: "#1C4FA7",
                      itemColor: "#888888",
                    },
                    Table: {
                      cellFontSize: 12,
                      fontSize: 12,
                      cellPaddingBlock: 12,
                      headerBg: "#FAFAFC",
                      rowSelectedBg: "#FAFAFC",
                      rowHoverBg: "#FAFAFC",
                      colorBgContainer: "white",
                    },
                    Breadcrumb: {
                      lastItemColor: "#002655",
                      itemColor: "#888",
                      iconFontSize: 14,
                      lineWidthFocus: 10,
                    },
                    Segmented: {
                      itemSelectedBg: "#dedefa",
                      itemColor: "#667085",
                      itemHoverColor: "#667085",
                      itemSelectedColor: "#4543AE",
                    },
                    Menu: {
                      itemHoverBg: "#ebebed",
                      itemActiveBg: "#ECFBFF",
                      fontSize: 12,
                    },
                  },
                }}
            >
              <RouterProvider router={appRouter} />
            </ConfigProvider>
          </LoadingStateProvider>
        </FormProvider>
      </AuthProvider>
    </div>
  );
}

export default App;
